import React from 'react'
import {Container} from 'react-bootstrap'

import Layout from '../components/Layout/Layout'

const Privacy = () => (
  <Layout>
    <div style={{ backgroundColor: "#ebf9ff" }}>
      <div className="page-header">
        <Container>
          <h1 style={{ padding: "40px", paddingBottom: "10px" }}>
            Privacy Policy
          </h1>
          <p style={{ color: "black", paddingBottom: "30px" }}>February 2020</p>
        </Container>
      </div>
    </div>
    <Container style={{ maxWidth: "750px", paddingTop: "30px" }}>
      <p>
        Thank you for visiting GridFabric.io. We believe in managing the privacy
        of individuals and making safe and ethical use of personal information
        that is provided to us. This page informs you of our policies regarding
        the collection, use, and disclosure of personal data when you use our
        website. We use usage data to provide and improve this website. By using
        this website, you agree to the collection and use of information in
        accordance with this policy.
      </p>{" "}
      <h4>Types of Data Collected</h4>
      <h6>Personal Data</h6>
      <p>
        While using our website, you may provide us with certain personally
        identifiable information that can be used to contact or identify you
        (“Personal Data”). Personally identifiable information may include, but
        is not limited to:
      </p>
      <ul>
        <li>Email Address</li>
        <li>First and Last Name</li>
        <li>Phone Number</li>
        <li>Address</li>
        <li>Cookies and usage data</li>
      </ul>
      <p>
        We may use your Personal Data to contact you with newsletters, marketing
        or promotional materials and other information that may be of interest
        to you. You may opt out of receiving any, or all, of these
        communications from us by following the unsubscribe link or instructions
        provided in any email we send or by contacting us. Other than personal
        information that is explicitly provided, it is not tracked.
      </p>
      <h6>Usage Data</h6>
      <p>
        We may also collect information how our website is accessed and used
        (“Usage Data”). This Usage Data may include information such as your
        computer’s Internet Protocol address (e.g. IP address), browser type,
        browser version, the pages of our website that you visit, the time and
        date of your visit, the time spent on those pages, unique device
        identifiers and other diagnostic data.
      </p>
      <h6>Tracking {"&"} Cookies Data</h6>
      <p>
        We use cookies and similar tracking technologies to track the activity
        on our website and hold certain information.
      </p>
      <p>
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use some portions of our website.
      </p>
      <p>
        Cookies are files with small amount of data which may include an
        anonymous unique identifier. Cookies are sent to your browser from a
        website and stored on your device. Tracking technologies also used are
        beacons, tags, and scripts to collect and track information and to
        improve and analyze our website.
      </p>
      <p>Examples of Cookies we use:</p>
      <ul>
        <li>
          <b>Session Cookies: </b>We use Session Cookies to track website usage
        </li>
        <li>
          <b>Preference Cookies: </b> We use Preference Cookies to maintain a
          record of your consent to use the website
        </li>
      </ul>
      <h4>Retention of Data</h4>
      <p>
        GridFabric, LLC will retain your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.{" "}
      </p>
      <p>
        GridFabric, LLC will also retain Usage Data for internal analysis
        purposes. Usage Data is generally retained for a shorter period of time,
        except when this data is used to strengthen the security or to improve
        the functionality of our website, or we are legally obligated to retain
        this data for longer time periods.
      </p>
      <h4>Transfer of Data</h4>
      <p>
        Your information, including Personal Data, may be transferred to — and
        maintained on — computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ than those from your jurisdiction.
      </p>
      <p>
        If you are located outside United States and choose to provide
        information to us, please note that we transfer the data, including
        Personal Data, to United States and process it there.
      </p>
      <p>
        Your consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer.
      </p>
      <p>
        GridFabric, LLC will take all steps reasonably necessary to ensure that
        your data is treated securely and in accordance with this Privacy Policy
        and no transfer of your Personal Data will take place to an organization
        or a country unless there are adequate controls in place including the
        security of your data and other personal information.
      </p>
      <h4>Disclosure of Data</h4>
      <h6>Disclosure for Law Enforcement</h6>
      <p>
        Under certain circumstances, GridFabric, LLC may be required to disclose
        your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </p>
      <h6>Legal Requirements</h6>
      <p>
        GridFabric, LLC may disclose your Personal Data in the good faith belief
        that such action is necessary to:
      </p>
      <ul>
        <li>To comply with a legal obligation </li>
        <li>To protect and defend the rights or property of GridFabric, LLC</li>
        <li>
          To prevent or investigate possible wrongdoing in connection with the
          website{" "}
        </li>
        <li>
          To protect the personal safety of users of the website or the public
        </li>
        <li>To protect against legal liability</li>
      </ul>
      <h4>Security of Data</h4>
      <p>
        The security of your data is important to us, but remember that no
        method of transmission over the Internet, or method of electronic
        storage is 100% secure. While we strive to use commercially acceptable
        means to protect your Personal Data, we cannot guarantee its absolute
        security.
      </p>
      <h4>
        “Do Not Track” Signals Under California Online Privacy Protection Act
        (CalOPPA)
      </h4>
      <p>
        We do not support Do Not Track (“DNT”). Do Not Track is a preference you
        can set in your web browser to inform websites that you do not want to
        be tracked.
      </p>
      <p>
        You can enable or disable Do Not Track by visiting the Preferences or
        Settings page of your web browser.
      </p>
      <h4>
        Your Data Protection Rights Under General Data Protection Regulation
        (GDPR)
      </h4>
      <p>
        If you are a resident of the European Economic Area (EEA), you have
        certain data protection rights. GridFabric, LLC aims to take reasonable
        steps to allow you to correct, amend, delete, or limit the use of your
        Personal Data.
      </p>
      <p>
        If you wish to be informed what Personal Data we hold about you and if
        you want it to be removed from our systems, please contact us.
      </p>
      <p>
        In certain circumstances, you have the following data protection rights:
      </p>
      <ul>
        <li>
          <b>
            The right to access, update or to delete the information we have on
            you.
          </b>{" "}
          Whenever made possible, you can access, update or request deletion of
          your Personal Data directly within your account settings section. If
          you are unable to perform these actions yourself, please contact us to
          assist you.{" "}
        </li>
        <li>
          <b>The right of rectification.</b> You have the right to have your
          information rectified if that information is inaccurate or incomplete.
        </li>
        <li>
          <b>The right to object.</b> You have the right to object to our
          processing of your Personal Data.
        </li>
        <li>
          <b>The right of restriction.</b> You have the right to request that we
          restrict the processing of your personal information.
        </li>
        <li>
          <b>The right to data portability.</b> You have the right to be
          provided with a copy of the information we have on you in a
          structured, machine-readable and commonly used format.
        </li>
        <li>
          <b>The right to withdraw consent.</b> You also have the right to
          withdraw your consent at any time where GridFabric, LLC relied on your
          consent to process your personal information.
        </li>
      </ul>
      <p>
        Please note that we may ask you to verify your identity before
        responding to such requests.
      </p>
      <p>
        You have the right to complain to a Data Protection Authority about our
        collection and use of your Personal Data. For more information, please
        contact your local data protection authority in the European Economic
        Area (EEA).
      </p>
      <h4>Service Providers</h4>
      <p>
        We may employ third party companies and individuals to facilitate our
        website (“Service Providers”), or to assist us in analyzing how our
        website is used.
      </p>
      <p>
        These third parties have access to your Personal Data only to perform
        these tasks on our behalf and are obligated not to disclose or use it
        for any other purpose.
      </p>
      <h4>Analytics</h4>
      <p>
        We may use third-party Service Providers to monitor and analyze the use
        of our website.
      </p>
      <ul>
        <li>
          <b>Google Analytics</b>. Google Analytics is a web analytics service
          offered by Google that tracks and reports website traffic. Google uses
          the data collected to track and monitor the use of our Service. This
          data is shared with other Google services. Google may use the
          collected data to contextualize and personalize the ads of its own
          advertising network.You can opt-out of having made your activity on
          the Service available to Google Analytics by installing the Google
          Analytics opt-out browser add-on. The add-on prevents the Google
          Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing
          information with Google Analytics about visits activity.For more
          information on the privacy practices of Google, please visit the
          Google Privacy & Terms web page:
          https://policies.google.com/privacy?hl=en
        </li>
      </ul>
      <h4>Behavioral Remarketing</h4>
      <p>
        GridFabric, LLC uses remarketing services to advertise on third party
        websites to you after you visited our Service. We and our third-party
        vendors use cookies to inform, optimize and serve ads based on your past
        visits to our Service.
      </p>
      <ul>
        <li>
          <b>Google AdWords.</b> Google AdWords remarketing service is provided
          by Google Inc.You can opt-out of Google Analytics for Display
          Advertising and customize the Google Display Network ads by visiting
          the Google Ads Settings page: http://www.google.com/settings/adsGoogle
          also recommends installing the Google Analytics Opt-out Browser Add-on
          – https://tools.google.com/dlpage/gaoptout – for your web browser.
          Google Analytics Opt-out Browser Add-on provides visitors with the
          ability to prevent their data from being collected and used by Google
          Analytics.For more information on the privacy practices of Google,
          please visit the Google Privacy & Terms web page:
          https://policies.google.com/privacy?hl=en
        </li>
      </ul>
      <h4>Links to Other Sites</h4>
      <p>
        Our website may contain links to other sites that are not operated by
        us. If you click on a third party link, you will be directed to that
        third party’s site. We strongly advise you to review the Privacy Policy
        of every site you visit. We have no control over and assume no
        responsibility for the content, privacy policies or practices of any
        third party sites or services.
      </p>
      <h4>Changes to This Privacy Policy</h4>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <p>
        We may let you know via email and/or a prominent notice on our website,
        prior to the change becoming effective and update the “effective date”
        at the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
      <h4>Contact Us</h4>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at{" "}
        <a
          href="mailto:support@gridfabric.zendesk.com"
          subject="Privacy concern"
        >
          support@gridfabric.zendesk.com
        </a>
      </p>
    </Container>
  </Layout>
)

export default Privacy